// Variable overrides
.card {
  margin-bottom: 0.5rem !important;
}

.form-control {
  font-size: 1.1rem !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f2f2f2 !important;
}

.red-border {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0deg 0% 100%);
  border-color: hsl(0deg 0% 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
}

.gray-datepicker {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0deg 0% 95%);
  border-color: hsl(0deg 0% 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
}

.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.modal {
  font-size: 12px;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;
}

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #fff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

#upload-button {
  display: inline-block;
  margin-top: 1.5rem;
}

#upload-button input[type="file"] {
  display: none;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255 255 255 / 80%);
  transition: all 0.3s ease;
  z-index: 0 !important;
  pointer-events: none;
  padding: 20px;
  color: rgb(0 0 0 / 50%);
}

.button-navrom {
  display: inline-block;
  color: #444;
  border: 1px solid #ccc;
  background: #ddd;
  box-shadow: 0 0 5px -1px rgb(0 0 0 / 20%);
  cursor: pointer;
  vertical-align: middle;
  max-width: 100px;
  padding: 5px;
  text-align: center;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.header-left {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
}

.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}

.leaflet-container {
  z-index: 0 !important;
}
