// Here you can add other styles
.numberCircle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px;
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 22px Arial, sans-serif;
  margin-left: 5px;
  margin-right: 5px;
}

.textSetari {
  bottom: 6px;
  position: relative;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop20 {
  margin-top: 20px;
}

#harta {
  height: 100%;
}

#hartaistoric {
  height: 55vh;
}

.nav-dropdown-items > .nav-item {
  padding-left: 20px !important;
}

.main .container-fluid {
  padding: 0 !important;
}

.app-footer {
  line-height: 0.5 !important;
}

.form-group {
  margin-bottom: 0.1rem !important;
}

.leaflet-popup-content p {
  line-height: 0px !important;
}

.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  line-height: 0.7rem !important;
}

.RadioGroupNavrom {
  display: flex;
}

.RadioNavrom {
  margin: 10px;
}

.breadcrumb {
  border-bottom: none !important;
  padding: 0 !important;
  margin-bottom: 0.5rem !important;
}

.card-header {
  padding: 0.02rem 1.25rem !important;
}

.card-header-actions > .btn {
  margin: 3px 0;
}

.language {
  margin-left: 2px;
  margin-right: 2px;
}

.languageDiv {
  margin-right: 10px;
}

.NavromSmall {
  font-size: small;
}

.NavromNormal {
  font-size: medium;
}

.NavromLarge {
  font-size: large;
}

.NavromExtra {
  font-size: x-large;
}

.CheckboxList {
  background-color: lightgray;
  padding: 0.5rem;
  border-style: solid;
}

.CheckboxListEl {
  padding: 0.2rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 0.25rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 0 rgb(0 0 0 / 5%) !important;
}

.ReactTable {
  z-index: 0 !important;
}

.popup-overlay {
  overflow: auto;
}
